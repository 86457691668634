<template>
  <div class="h-100dvh over-flow-y-scroll">
    <component :is="layoutTemplate" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../utils/template'

import template1 from './template/indexTemplate1.vue'
import template3 from './template/indexTemplate3.vue'
import template4 from './template/indexTemplate4.vue'

export default {
  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },

}
</script>

<style lang="scss" scoped>
.user-wallet {
	font-size: 28px;
}

.wallet-center {
	height: 36px;
	border-radius: 16px;
}

.dark-bg {
	background-image: linear-gradient(96deg, #e6ce71 -6%, #bc954d 19%, #a57539 49%, #c09a50 77%, #e6ce71 100%), linear-gradient(96deg, #e6ce71 -6%, #bc954d 19%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}

.user-wallet {
	font-size: 24px;
}

</style>
