<template>
  <div id="profile">
    <h1>4</h1>
    <v-form
      ref="form"
      v-model="veridate"
    >
      <v-container>
        <v-row class="pt-4 pb-3">
          <!-- 開戶姓名 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.bankAccountName") }}
            </span>
            <!-- :disabled="disable.username" -->
            <v-text-field
              v-model.trim="form.username"
              :disabled="disable.username"
              :readonly="disable.username"
              :placeholder="$t('form.missName')"
              :rules="[rules.required.userName, rules.length.userName]"
              dense
              class="mt-2"
              single-line
              outlined
            >
              <div
                v-if="canChangeUsername && disable.username"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('username')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 性別 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.gender") }}
            </span>
            <v-text-field
              :placeholder="$t('form.missGender')"
              :value="genderDisplay"
              :disabled="disable.gender"
              :rules="[rules.required.gender]"
              class="mt-2"
              :readonly="disable.gender"
              dense
              single-line
              outlined
              @click="selectDialog = true"
            >
              <div
                v-if="canChangeGender && disable.gender"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('gender')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 出生日期 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.birthday") }}
            </span>

            <!-- 日期選擇器彈窗 -->
            <v-dialog
              ref="dialogFrom"
              v-model="datePicker"
              width="290px"
            >
              <!-- input -->
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.birthdate"
                  :disabled="disable.birthdate"
                  :readonly="disable.birthdate"
                  :placeholder="$t('form.missBirthday')"
                  class="mt-2"
                  dense
                  single-line
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    v-if="canChangeBirthday && disable.birthdate"
                    slot="append"
                  >
                    <Icon
                      data="@icon/edit.svg"
                      width="24px"
                      height="24px"
                      class="primary--text cursor-pointer"
                      @click="editItem('birthday')"
                    />
                  </div>
                </v-text-field>
              </template>

              <!-- 日期選擇器 -->
              <v-date-picker
                v-model="form.birthdate"
                class="bg"
                :max="legaAge"
                header-color="bg"
                color="secondary"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="subtitle"
                  class="subtitle-1 font-weight-medium"
                  @click="cancelPickerBirthday"
                >
                  {{ $t("global.cancel") }}
                </v-btn>

                <v-btn
                  class="subtitle-1 font-weight-medium"
                  text
                  color="primary"
                  @click="datePicker = false"
                >
                  {{ $t("global.confirmation") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <!-- 手机号码 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.mobile") }}
            </span>
            <v-text-field
              v-model.trim="form.contact_no"
              :placeholder="$t('form.missMobile')"
              :disabled="disable.contact_no"
              :readonly="disable.contact_no"
              :rules="[
                rules.required.mobile
              ]"
              class="mt-2"
              type="number"
              dense
              single-line
              outlined
            >
              <div
                v-if="canChangePhoneNumber && disable.contact_no"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('mobile')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 验证码區塊 -->
          <v-col
            v-if="!disable.contact_no && validationType === 'sms'"
            cols="12"
            class="px-3 py-1"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-2 pb-2"
              >
                <span class="title--text caption font-weight-medium">
                  {{ $t("form.missCaptcha") }}
                </span>
              </v-col>

              <!-- 验证码 輸入 -->
              <v-col
                cols="8"
                class="py-0 pr-1"
              >
                <v-text-field
                  v-model.trim="mobileVerifyCodeSMS"
                  dense
                  :placeholder="$t('form.missVerifyCode')"
                  :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                  single-line
                  outlined
                />
              </v-col>

              <!-- 發送驗證碼 BUTTON -->
              <v-col
                cols="4"
                class="py-0 pl-1"
              >
                <v-btn
                  :disabled="disable.verifiMobileCode"
                  block
                  outlined
                  color="primary"
                  class="px-0 caption"
                  @click="verifiMobileCode"
                >
                  <!-- 發送驗證碼 -->
                  <template v-if="verifiMobileCodeBtnSec === 60">
                    {{ $t("myCenter.sendVerificationCode") }}
                  </template>

                  <!-- 59后重試 -->
                  <template v-else>
                    {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- 电子邮箱 -->
          <v-col
            cols="12"
            class="px-3 py-1"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.email") }}
            </span>

            <!-- 不需要驗証 (电子邮箱) -->
            <v-text-field
              v-if="validationType !== 'email'"
              v-model.trim="form.email"
              :disabled="disable.email"
              :readonly="disable.email"
              :placeholder="$t('form.missEmail')"
              :rules="[rules.required.mail, rules.format.mail]"
              dense
              class="mt-2"
              single-line
              outlined
            >
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('email')"
                />
              </div>
            </v-text-field>

            <!-- 需要驗証 (电子邮箱) -->
            <v-text-field
              v-else
              v-model.trim="form.email"
              :disabled="disable.email && form.email !== ''"
              :placeholder="$t('form.missEmail') "
              :rules="[rules.required.mail, rules.format.mail]"
              class="mt-2"
              dense
              single-line
              outlined
              readonly
              @click="emailDialog.status = true"
            >
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('email')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 取款密码 -->
          <v-col
            :cols="form.withdrawal_key ? 6 : 12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.withdrawPassword") }}
            </span>
            <v-text-field
              v-model.trim="form.withdrawal_key"
              :disabled="disable.withdrawal_key"
              :readonly="disable.withdrawal_key"
              :placeholder="$t('form.misswithdrawPassword')"
              :rules="[
                rules.required.withdrawal_key,
                rules.length.withdrawal_key,
              ]"
              class="mt-2"
              type="password"
              single-line
              dense
              outlined
            >
              <div
                v-if="canChangeWithdrawalPassword && disable.withdrawal_key"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('withdrawPassword')"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <!-- 已設定過 說明文字 -->
            <template v-if="disable.withdrawal_key">
              <div class="text-center caption comment--text">
                {{ $t("myCenter.contactCsInfo") }}
              </div>
            </template>

            <!-- 未設定 保存按鈕  :disabled="!veridate"-->
            <template v-else>
              <v-btn
                class="mt-2"
                block
                height="44"
                color="primary"
                :disabled="!veridate"
                @click="preSetUserProfile"
              >
                <span class="subtitle-1 btnText--text">
                  {{ $t("global.save") }}
                </span>
              </v-btn>
            </template>

            <!-- 使用者修改登入密碼-->
            <template v-if="showUserChangePasswordBtn">
              <div class="text-center caption comment--text">
                <v-btn
                  class="mt-3"
                  color="danger"
                  height="44"
                  block
                  @click="userPasswordDialog.status = true"
                >
                  {{ $t("form.modifyLoginPassword") }}
                </v-btn>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>

      <!-- DIALOG 選擇性別 -->
      <Select
        :status="selectDialog.status"
        :height="188"
        :width="240"
      >
        <v-row
          slot="title"
          class="p-sticky top-0 bg"
        >
          <!-- 選擇性別 -->
          <v-col
            cols="12"
            class="text-h6 title--text px-6 py-4 p-sticky"
          >
            {{ $t("global.chooseGender") }}
          </v-col>
        </v-row>

        <v-row slot="list">
          <v-col
            v-for="(item, index) in selectDialog.item"
            :key="index"
            cols="12"
            class="px-6 title--text"
            :class="[{ secondary: form.gender === Object.keys(item)[0] }]"
            @click="setGender(Object.keys(item)[0], form)"
          >
            {{ Object.values(item)[0] }}
          </v-col>
        </v-row>
      </Select>

      <!-- 保存前 提醒彈窗 -->
      <Dialog
        :dialog="preSaveDialog"
        :width="280"
        :height="140"
      >
        <div
          slot="text"
          class="px-6"
        >
          {{ $t("myCenter.savePersonalInfo") }}
        </div>

        <!-- 取消 -->
        <v-btn
          slot="btn"
          text
          class="subtitle--text"
          @click="preSaveDialog = false"
        >
          {{ $t("global.cancel") }}
        </v-btn>

        <!-- 保存 發送表單 -->
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="editUserProfile(form)"
        >
          {{ $t("global.confirmation") }}
        </v-btn>
      </Dialog>
    </v-form>

    <!-- 手機驗証碼 錯誤 彈窗 -->
    <Dialog
      :height="140"
      :dialog="verifyMobileCodeErrorDialog"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t("form.errorCaptcha") }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="resetMobileVerfifyStatus"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- 修改登入密碼 dialog -->
    <Dialog :dialog="userPasswordDialog.status">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <v-form
          ref="mdfUserPasswordForm"
          v-model="userPasswordDialog.form.status"
        >
          <!-- TITLE -->
          <p>
            {{ $t("form.modifyLoginPassword") }}
          </p>

          <!-- 驗証 信箱 === 'email' -->
          <v-row v-if="validationType === 'email'">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- email 驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.emailCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(form, 'edit')"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 驗証 手機 === 'sms' -->
          <v-row v-if="validationType === 'sms'">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.mobileCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="mobileVerifyCodeSMS"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <!-- 手機發送驗証碼 -->
              <v-btn
                :disabled="verifiMobileCodeBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="verifiMobileCode"
              >
                <!-- 發送驗證碼 -->
                <template v-if="verifiMobileCodeBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <!-- 輸入登入密碼 -->
          <div class="input">
            <!-- 原有密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.missPassword") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.password"
              type="password"
              :placeholder="$t('form.missPassword')"
              class="mt-2"
              dense
              single-line
              outlined
              :rules="[rules.required.withdrawal_key]"
            />

            <!-- 新密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.newPassword") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPassword"
              type="password"
              :placeholder="$t('form.enterNewPassword')"
              :rules="[rules.required.enterNewPassword, rules.length.pwd]"
              class="mt-2"
              dense
              single-line
              outlined
            />

            <!-- 再次輸入新密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.newPasswordConfirm") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPasswordConfirm"
              type="password"
              :placeholder="$t('form.enterNewPasswordAgain')"
              :rules="[
                rules.required.enterNewPasswordAgain,
                rules.format.enterNewPasswordAgain,
              ]"
              class="mt-2"
              dense
              single-line
              outlined
              @keyup.enter="userLoginPasswordChange"
            />
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="resetUserLoginPasswordChangeDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="userLoginPasswordChange"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- EMAIL 驗証 -->
    <Dialog :dialog="emailDialog.status">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <p>{{ emailDialog.title }}</p>
        <v-form
          ref="emailDialogForm"
          v-model="emailDialog.form.status"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 新email -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.email") }}
              </span>
            </v-col>
            <v-col
              cols="8"
              class="py-0"
            >
              <v-text-field
                ref="emailInput"
                v-model.trim="emailDialog.form.email"
                type="email"
                :placeholder="$t('form.missEmail')"
                :rules="[rules.required.mail, rules.format.mail]"
                dense
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="4"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(emailDialog.form)"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>

            <!-- 驗証碼 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="title--text caption font-weight-medium">
                {{ $t("form.missCaptcha") }}
              </span>
            </v-col>

            <!-- 验证码 輸入 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="emailDialogClose"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="emailDialogConfirm(emailDialog.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- 修改各項目 dialog -->
    <Dialog :dialog="edit.dialogStatus">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <v-form
          ref="editForm"
          v-model="edit.validate"
        >
          <!-- TITLE -->
          <p>
            {{ $t('global.edit') + edit.dialogTitle }}
          </p>

          <!-- 驗証 信箱 === 'email' -->
          <v-row v-if="(validationType === 'email' && edit.type !== 'email') || (edit.type === 'mobile' && validationType !== 'none')">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- email 驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.emailCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(emailDialog.form, 'edit')"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 驗証 手機 === 'sms' -->
          <v-row v-if="(validationType === 'sms' && edit.type !== 'mobile' )|| (edit.type === 'email' && validationType !== 'none')">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.mobileCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="mobileVerifyCodeSMS"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <!-- 手機發送驗証碼 -->
              <v-btn
                :disabled="verifiMobileCodeBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="verifiMobileCode"
              >
                <!-- 發送驗證碼 -->
                <template v-if="verifiMobileCodeBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 修改手機 -->
          <template v-if="edit.type === 'mobile'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.newMobile") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.contact_no"
                :placeholder="$t('form.missMobile')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[
                  rules.required.mobile
                ]"
              />
            </div>
          </template>

          <!-- 修改信箱 -->
          <template v-if="edit.type === 'email'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t('global.editNew') + $t("myCenter.email") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.email"
                :placeholder="$t('form.missEmail')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[rules.required.mail, rules.format.mail]"
              />
            </div>
          </template>

          <!-- 修改姓名 -->
          <template v-if="edit.type === 'username'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t('global.editNew') + $t("myCenter.bankAccountName") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.username"
                :placeholder="$t('form.missName')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[rules.required.userName, rules.length.userName]"
              />
            </div>
          </template>

          <!-- 修改性別 -->
          <template v-if="edit.type === 'gender'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.gender") }}
              </span>
              <v-select
                v-model="edit.form.gender"
                :items="genderItems"
                item-text="gender"
                item-value="value"
                dense
                outlined
              />
            </div>
          </template>

          <!-- 修改出生日期 -->
          <template v-if="edit.type === 'birthday'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.birthday") }}
              </span>
              <!-- 出生日期 -->
              <v-col
                cols="12"
                class="py-0 px-0"
              >
                <!-- 日期選擇器彈窗 -->
                <v-dialog
                  ref="dialogFrom"
                  v-model="datePicker"
                  persistent
                  width="290px"
                >
                  <!-- input -->
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="edit.form.birthdate"
                      :placeholder="$t('form.missBirthday')"
                      :rules="[rules.required.birthdate]"
                      class="mt-2"
                      dense
                      single-line
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <!-- 日期選擇器 -->
                  <v-date-picker
                    v-model="edit.form.birthdate"
                    class="bg"
                    :max="legaAge"
                    header-color="bg"
                    color="secondary"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="subtitle"
                      class="subtitle-1 font-weight-medium"
                      @click="chancelEditPickerDate"
                    >
                      {{ $t("global.cancel") }}
                    </v-btn>

                    <v-btn
                      class="subtitle-1 font-weight-medium"
                      text
                      color="primary"
                      @click="datePicker = false"
                    >
                      {{ $t("global.confirmation") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </div>
          </template>

          <!-- 修改取款密碼 -->
          <div
            v-if="edit.type ==='withdrawPassword'"
            class="input"
          >
            <!-- 新取款密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('myCenter.newWithdrawPassword') }}
            </span>
            <v-text-field
              v-model.trim="edit.form.withdrawal_key"
              type="password"
              :placeholder="$t('myCenter.newWithdrawPassword')"
              :rules="[rules.required.enterNewPassword, rules.length.pwd]"
              class="mt-2"
              dense
              single-line
              outlined
            />

            <!-- 再次輸入新取款密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.newWithdrawPasswordConfirm") }}
            </span>
            <v-text-field
              v-model.trim="edit.check_withdrawal_key"
              type="password"
              :placeholder="$t('myCenter.newWithdrawPasswordConfirm')"
              :rules="[
                rules.required.enterNewPasswordAgain,
                rules.format.enterNewWithdrawKeyAgain,
              ]"
              class="mt-2"
              dense
              single-line
              outlined
              @keyup.enter="userLoginPasswordChange"
            />
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="chancelEditDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="editUserProfileItem(edit.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import profileMixins from '../mixins/profileMixins'

export default {
  mixins: [profileMixins],
}
</script>

<style lang="scss" scoped>

</style>
