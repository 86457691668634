import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { detectDevIsLineLiffMode } from '@/utils/liff'
import { validateMobileNumberByNation } from '@/utils/validate'
import DialogTemplate3 from '@/components/dialog/DialogTemplate3.vue'
import Dialog from '@/components/dialog'
import Select from '@/components/dialog/Select'
import validator from 'validator'

export default {
  components: {
    Select,
    Dialog,
    DialogTemplate3,
  },

  data: () => ({
    phoneCountryCode: null, // 手機前綴國碼
    veridate: false, // 表單驗証
    h5ModeCompeletedBaseInfo: false, // H5模式(非 Liff)下，個人資料是否完善

    form: {
      username: '', // 使用者姓名
      gender: '', // 性別 (男：male 女：female)
      birthdate: '', // 生日 (日期格式：YYYY-MM-DD，例"1998-02-02")
      contact_no: '', // 手機號碼
      email: '', // 電子信箱
      withdrawal_key: '', // 取款密碼 (必須六位數)
    },
    mobileVerifyCodeSMS: '', // 手機驗証碼
    varifyCodeStatus: true,
    verifyCodeFetchTimeId: '',

    verifiMobileCodeBtnSec: 60, // 倒數秒數
    timeId: '', // 計時器

    disable: {
      username: false,
      gender: false,
      birthdate: false,
      contact_no: false,
      email: false,
      withdrawal_key: false,
      verifiMobileCode: true,
    },

    preSaveDialog: false,
    dialog: false, // selectDialog.status (不直接在此操作)
    datePicker: false,

    // 驗証碼錯誤彈窗
    verifyMobileCodeErrorDialog: false,
    userPasswordDialog: {
      status: false,
      form: {
        status: false,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    },
    emailDialog: {
      status: false,
      title: '新增电子邮箱',
      validate: {
        timeID: null,
        codeBtn: true,
        sendBtnSec: 60,
      },
      form: {
        status: false,
        email: '',
        validateCode: '',
      },
    },

    // 修改資料
    edit: {
      dialogStatus: false,
      type: null,
      dialogTitle: null,
      validate: false,
      form: {
        birthdate: null,
      },
      check_withdrawal_key: null,
    },
    picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),

  computed: {

    genderAry() {
      return {
        male: this.$t('myCenter.male'),
        female: this.$t('myCenter.female'),
      }
    },
    ...mapState({
      // 來自 api 的驗証方式 none email sms
      validationType: state => state.websiteData.validation_method,

      // 判斷是否為 liff 模式
      isLiffMode() {
        return detectDevIsLineLiffMode() || window.liff.isInClient()
      },

      saveBtnShow() {
        return this.isLiffMode ? !this.completedBaseinfo : !this.h5ModeCompeletedBaseInfo
      },

      // 修改項目 狀態
      canChangeUsername: state => state.websiteData.func_switch.allow_member_change_username, // 姓名
      canChangeGender: state => state.websiteData.func_switch.allow_member_change_gender, // 性別
      canChangeBirthday: state => state.websiteData.func_switch.allow_member_change_birthdate, // 生日
      canChangePhoneNumber: state => state.websiteData.func_switch.allow_member_change_contact_no, // 手機
      canChangeEmail: state => state.websiteData.func_switch.allow_member_change_email, // 電子信箱
      canChangeWithdrawalPassword: state => state.websiteData.func_switch.allow_member_change_withdrawal_key, // 取款密碼
      mobileFormatNation: state => state.websiteData.phone_format_nation.toUpperCase(),
      phoneCountryCodeList: state => state.user.phoneCountryCodeList,
      completedBaseinfo: state => state.user.completedBaseInfo,
    }),

    ...mapGetters([
      'lang', // 目前設定語系
      'allowMemberChangePassword', // 允許修改使用者密碼
      'theme',
      'layout',
      'clientLang',
    ]),

    /**
     * 性別 選擇單窗 資料
     * @date 2021-05-21
     * @returns {Object} 性別資料、彈窗狀態
     */
    selectDialog: {
      get() {
        return {
          status: this.dialog,

          item: [
            { male: this.$t('myCenter.male') },
            { female: this.$t('myCenter.female') },
          ],
        }
      },

      /**
       * 描述
       * @date 2021-05-21
       * @param {String} status  性別彈窗狀態操作
       */
      set(status) {
        this.dialog = status
      },
    },

    /**
     * 在性別 input 的顯示
     * @date 2021-05-21
     * @returns {String} 目前選擇的性別(form.gender)，無 => 為空
     */
    genderDisplay() {
      const genderItem = this.selectDialog.item.filter(
        item => Object.keys(item)[0] === this.form.gender,
      )[0]
      return genderItem ? Object.values(genderItem)[0] : ''
    },

    // 滿十八歲日期
    legaAge() {
      const year = new Date().getFullYear() - 18
      const mon = new Date().getMonth() + 1
      const day = new Date().getDate() - 1
      return `${year}-${mon < 10 ? '0' + mon : mon}-${
        day < 10 ? '0' + day : day
      }`
    },

    // 表單驗證
    rules() {
      return {
        // 必填
        required: {
          userName: value => !!value || this.$t('form.missName'),
          gender: value => !!value || this.$t('form.missGender'),
          birthdate: value => !!value || this.$t('form.missBirthday'),
          mobile: value => {
            // 手機有值 且 為 60 秒
            if (value && this.verifiMobileCodeBtnSec === 60) {
              this.disable.verifiMobileCode = false
            } else if (!value) {
              this.disable.verifiMobileCode = true
            }
            return !!value || this.$t('form.missMobile')
          },
          smsCode: value => !!value || this.$t('form.missVerifyCode'),
          mail: val => {
            if (this.isLiffMode) return true
            return !!val || this.$t('form.missEmail')
          },
          withdrawal_key: val => {
            if (this.isLiffMode) return true
            return !!val || this.$t('form.missPassword')
          },
          enterNewPassword: val => !!val || this.$t('form.enterNewPassword'),
          enterNewPasswordAgain: val =>
            !!val || this.$t('form.enterNewPasswordAgain'),
        },
        // 長度
        length: {
          userName: value =>
            (value && value.length > 1) || this.$t('form.errorName'),
          withdrawal_key: val => {
            if (this.isLiffMode) return true
            return (val && val.length > 5) || this.$t('form.errorPasswordFormat')
          },
          pwd: value =>
            (value && value.length > 5) || this.$t('form.errorPasswordFormat'),
        },
        // 格式
        format: {
          /**
           * 手機號碼格式確認 (中國電話 13575769526)
           * @date 2021-05-25
           * @param {string} phoneNumber 手機號碼
           * @returns {boolean} 是否符合手機格式
           */
          mobile: phoneNumber => {
            // TODO 泰國語系尚未調整為 'th-TH' (確認 api wtoken/lang 是否為語系代碼) => 調整回 this.lang
            const validate = validateMobileNumberByNation(phoneNumber, this.mobileFormatNation)
            this.$log(`手號碼驗證: ${validate}`)
            // 發送驗証碼 狀態 (如果不在計時中)
            if (this.verifiMobileCodeBtnSec === 60) {
              this.disable.verifiMobileCode = !validate
            }
            return validate || this.$t('form.errorMobile')
          },
          mail: email =>
            (email && validator.isEmail(email)) || this.$t('form.errorEmail'),
          verifyMobile: code =>
            !!this.varifyCodeStatus || this.$t('form.errorCaptcha'),
          enterNewPasswordAgain: password => {
            if (password === this.userPasswordDialog.form.newPassword) {
              return true
            }
            return this.$t('form.errorEnterNewPasswordAgain')
          },

          enterNewWithdrawKeyAgain: password => {
            if (password === this.edit.form.withdrawal_key) return true
            return this.$t('form.errorEnterNewPasswordAgain')
          },
        },
      }
    },

    // 是否顯示 修改登入密碼 按鈕
    showUserChangePasswordBtn() {
      if (this.isLiffMode) return false
      if (!this.allowMemberChangePassword) return false
      if (this.allowMemberChangePassword && (this.validationType === 'sms' && !this.disable.verifiMobileCode)) return false
      if (this.allowMemberChangePassword && (this.validationType === 'email' && !this.disable.email)) return false
      if (this.allowMemberChangePassword) return true

      return false
    },

    genderItems() {
      return [
        { gender: this.$t('myCenter.male'), value: 'male' },
        { gender: this.$t('myCenter.female'), value: 'female' },

      ]
    },

    i18nLocal() {
      return this.$i18n.locale
    },
  },

  watch: {
    datePicker() {
      this.$nextTick(() => document.querySelector('.v-picker__title').classList.add('title--text'))
    },
  },

  mounted() {
    this.getPhoneCountryCode()
    this.getUserBaseInfo()
  },

  methods: {
    ...mapActions([
      'get_user_base_info',
      'edit_user_profile',
      'get_mobile_verify_SMS',
      'verify_mobile_code',
      'show_alert',
      'modify_user_password',
      'user_logout',
      'send_email_validate_code',
      'validate_email_validate_code',
      'get_phone_country_code',
    ]),

    ...mapMutations(['SET_COMPLETED_BASEINFO']),

    async getPhoneCountryCode() {
      // 取得手機國碼
      await this.get_phone_country_code()

      // 指定默認 國家手機碼 state.websiteData.phone_format_nation.toUpperCase()
      this.phoneCountryCode = this.phoneCountryCodeList.find(item => item.code === this.mobileFormatNation).country_code
    },

    cancelPickerBirthday() {
      this.form.birthdate = ''
      this.datePicker = false
    },

    chancelEditPickerDate() {
      this.edit.form.birthdate = null
      this.datePicker = false
    },

    chancelEditDialog() {
      this.edit.type = ''
      this.$refs.editForm.reset()
      this.getUserBaseInfo()
      this.edit.dialogStatus = false
    },

    // 修改項目
    editItem(type) {
      if (!type) return false
      this.edit.type = type

      // 預帶入必填項目
      if (this.edit.type !== 'username') this.edit.form.username = this.form.username
      if (this.validationType === 'email') this.emailDialog.form.email = this.form.email
      this.edit.form.contact_no = `${this.form.contact_no}`
      this.edit.form.email = this.form.email
      this.edit.form.birthdate = this.form.birthdate
      this.edit.form.gender = this.form.gender
      this.edit.form.withdrawal_key = this.form.withdrawal_key
      if (this.edit.type === 'withdrawPassword') this.edit.form.withdrawal_key = ''
      this.edit.dialogTitle = (type === 'username') ? this.$t('myCenter.bankAccountName') : this.$t(`myCenter.${type}`)
      this.edit.dialogStatus = true
    },

    // 送出修改
    async editUserProfileItem(editForm) {
      // 表單驗証
      this.$refs.editForm.validate()
      if (!this.edit.validate) return this.editUserProfileFail()

      // 信箱驗証
      if ((this.validationType === 'email' && this.edit.type !== 'email') || (this.edit.type === 'mobile' && this.validationType !== 'none')) {
        const { status } = await this.validate_email_validate_code(this.emailDialog.form)
        if (status === '0') return this.validateEmailCodeFail()
      }

      // 手機驗証
      if ((this.validationType === 'sms' && this.edit.type !== 'mobile') || (this.edit.type === 'email' && this.validationType !== 'none')) await this.finalCheckMobileValidateCode()

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      const cloneForm = { ...editForm }
      if (!cloneForm.birthdate) delete cloneForm.birthdate
      cloneForm.contact_no = `${this.phoneCountryCode}|${cloneForm.contact_no}`

      // LINE Liff 模式 不開放取款密碼、email
      if (this.isLiffMode) {
        delete cloneForm.withdrawal_key
        delete cloneForm.email
      }

      const res = await this.edit_user_profile(cloneForm)
      resStatus(res, this.editUserProfileSuccess, this.editUserProfileFail)
    },

    /**
     * 設置性別功能
     * @date 2021-05-26
     * @param {any} gender
     * @param {any} form
     */
    setGender(gender, form) {
      form.gender = gender
      this.selectDialog = false
    },

    /**
     * 倒數功能 (發送驗証碼)
     * @date 2021-05-26
     */
    reciprocal() {
      // disable 發送驗証碼 btn
      this.disable.verifiMobileCode = true

      const timeId = setInterval(() => {
        if (this.verifiMobileCodeBtnSec === 0) {
          // 如果為 0 即重置
          clearInterval(this.timeId)
          this.verifiMobileCodeBtnSec = 60

          if (this.form.contact_no) {
            this.disable.verifiMobileCode = false
          }

          // 每秒遞減
        } else this.verifiMobileCodeBtnSec--
      }, 1000)

      this.timeId = timeId
    },

    /**
     * 發送 sms 簡訊 (成功)
     * @date 2022-01-14
     * @param {array} data 成功信息
     */
    sendMobileVerifyCodeSuccess(data) {
      this.show_alert({
        icon: 'done',
        text: data[0],
      })
    },

    /**
     * 發送 sms 簡訊 (失敗)
     * @date 2022-01-14
     * @param {string} errorsMessage 失敗信息
     */
    sendMobileVerifyCodeFail(errorsMessage) {
      this.show_alert({
        icon: 'fail',
        text: errorsMessage,
      })
    },

    /**
     * 發送 sms 簡訊驗証
     * @date 2021-05-26
     * @param {String} phone 手機號碼
     */
    async sendMobileVerifyCode(phone) {
      const res = await this.get_mobile_verify_SMS(phone)
      resStatus(
        res,
        this.sendMobileVerifyCodeSuccess,
        this.sendMobileVerifyCodeFail,
      )
    },

    /**
     * 延遲 api 事件 (停止輸入一秒後打)
     * @date 2021-05-26
     * @param {Object} phoneAndCode 手機、驗証碼
     */
    delayFetch(phoneAndCode, fun) {
      if (!phoneAndCode.code) return false
      clearTimeout(this.verifyCodeFetchTimeId)

      this.verifyCodeFetchTimeId = setTimeout(() => {
        fun(phoneAndCode)
      }, 1000)
    },

    /**
     * 如果驗証碼 (正確)
     * @date 2021-05-26
     */
    verifyMobileCodeSuccess() {
      this.varifyCodeStatus = true
    },

    /**
     * 如果驗証碼 (錯誤)
     * @date 2021-05-26
     */
    verifyMobileCodeFail() {
      // 驗証錯誤彈窗 (開啟)
      this.verifyMobileCodeErrorDialog = true
      // 驗証錯誤碼狀態 fail
      this.varifyCodeStatus = false
    },

    /**
     * 重置驗証錯誤狀態 (驗証錯誤彈窗=>確認)
     * @date 2021-09-23
     */
    resetMobileVerfifyStatus() {
      // 驗証錯誤碼狀態 done (狀態回復)
      this.varifyCodeStatus = true
      // 驗証碼輸入 重置
      this.mobileVerifyCodeSMS = ''
      // 驗証表單驗証 (提醒使用者輪入)
      this.$refs.form.validate()
      // 開啟驗証錯誤彈窗 (關閉)
      this.verifyMobileCodeErrorDialog = false
    },

    /**
     * 驗証 手機驗証碼是否正確
     * @date 2021-05-26
     * @param {Object} phoneAndCode 手機、驗証碼
     */
    async verifyMobileCode(phoneAndCode) {
      const res = await this.verify_mobile_code(phoneAndCode)
      resStatus(res, this.verifyMobileCodeSuccess, this.verifyMobileCodeFail)
    },

    /**
     * 發送驗証碼 按鈕
     * 倒數 / 狀態重置
     * @date 2021-05-26
     * @returns {any}
     */
    verifiMobileCode() {
      // 發送 sms 簡訊

      console.log('verifiMobileCode')
      if (this.verifiMobileCodeBtnSec !== 60) return false
      this.sendMobileVerifyCode({
        phone: `${this.phoneCountryCode}|${this.form.contact_no}`,
      })

      // 倒數計時
      this.reciprocal()
    },

    /**
     * 使用者基本資料 寫入 form
     * @date 2021-05-20
     * @param {Object} form 修改基本資料表單
     * @param {Object} baseInfo 使用基本資料
     */
    userBaseInfoWriteToForm(form, baseInfo) {
      for (const item in baseInfo) {
        // 如果 api 索引 符合 form 索引 且 有資料
        if (form[item] !== undefined && baseInfo[item]) {
          // api 來的資料，input 將默認 disable
          this.disable[item] = true

          if (item === 'contact_no') {
            // 手機另處理 移除 `|`
            form[item] = baseInfo[item].split('|')[1]

            // 儲存來自資料的國碼
            const curryCountryCode = baseInfo[item].split('|')[0]
            this.phoneCountryCode = curryCountryCode
          } else if (item === 'withdrawal_key') {
            // 取款密碼特別處理
            form[item] = '123456'
          } else form[item] = baseInfo[item] || ''
        }
      }

      // 非 LineLiff 模式，要根據表單內容是否皆以填寫，判斷個人資料是否完善
      if (!this.isLiffMode) {
        this.h5ModeBaseInfoIsCompleted()
      }
    },

    // 判斷一般 H5 模式下，個人資料是否完善
    h5ModeBaseInfoIsCompleted() {
      const keys = Object.keys(this.form)
      let completeData = true

      keys.forEach(item => {
        if (this.form[item] === '') {
          completeData = false
        }
      })

      this.h5ModeCompeletedBaseInfo = completeData
    },

    /**
     * 取得使用者基本資料 (成功)
     * @date 2021-05-20
     * @param {Object} {member} 使用者基本資料
     */
    getUserBaseInfoSuccess({ member }) {
      // 基本資料 與 修改資料 索引不同 (修改)
      member.withdrawal_key = member.withdrawal_password
      // 取得的資料寫入 form
      this.userBaseInfoWriteToForm(this.form, member)
    },

    /**
     * 取得使用者基本資料
     * @date 2021-05-20
     */
    async getUserBaseInfo() {
      const res = await this.get_user_base_info()
      resStatus(res, this.getUserBaseInfoSuccess)
    },

    /**
     * 使用者修改個人資料 (成功)
     * @date 2021-05-26
     */
    editUserProfileSuccess() {
      this.show_alert({
        icon: 'done',
      })
      this.SET_COMPLETED_BASEINFO(true)
      if (this.edit.type) this.chancelEditDialog() // 如果是修改的話

      Object.assign(this.form, this.$options.data().form)
      this.$refs.form.resetValidation()
      this.getUserBaseInfo()
      this.getPhoneCountryCode()
    },

    /**
     * 使用者修改個人資料 (失敗)
     * @date 2021-05-26
     * @returns {any}
     */
    editUserProfileFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    // 手機確認驗証碼
    async finalCheckMobileValidateCode() {
      console.log('finalCheckMobileValidateCode')
      if (this.validationType === 'sms' || (this.edit.type === 'email' && this.validationType !== 'none')) {
        await this.verifyMobileCode({
          phone: `${this.phoneCountryCode}|${this.form.contact_no}`,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }
    },

    // 保存前最確認
    async preSetUserProfile() {
      if (this.validationType === 'sms') {
        await this.verifyMobileCode({
          phone: `${this.phoneCountryCode}|${this.form.contact_no}`,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      this.preSaveDialog = true
    },

    /**
     * (保存) 使用者修改個人資料
     * @date 2021-05-26
     * @param {Object} form 使用者資料
     */
    async editUserProfile(form) {
      // 關閉最後確認彈窗
      this.preSaveDialog = false
      // 驗証表單
      this.$refs.form.validate()
      // 如果表單沒有驗証過 或 sms 驗証碼沒有過
      if (!this.veridate) return this.editUserProfileFail()

      const cloneForm = { ...form }
      if (!cloneForm.birthdate) delete cloneForm.birthdate
      cloneForm.contact_no = `${this.phoneCountryCode}|${this.form.contact_no}`

      if (this.isLiffMode) {
        delete cloneForm.withdrawal_key
        delete cloneForm.email
      }
      const res = await this.edit_user_profile(cloneForm)
      resStatus(res, this.editUserProfileSuccess, this.editUserProfileFail)
    },

    /**
     * 使用者取消修改密碼 (dialog)
     * @date 2021-12-27
     */
    resetUserLoginPasswordChangeDialog() {
      this.$refs.mdfUserPasswordForm.reset()
      this.userPasswordDialog.status = false
    },

    /**
     * 使用者修改登入密碼 (失敗)
     * @date 2021-12-27
     * @param {string} error
     */
    userLoginPasswordChangeFail(errors) {
      this.failDialog(errors)
      this.resetUserLoginPasswordChangeDialog()
    },

    /**
     * 使用者修改登入密碼 (成功)
     * @date 2021-12-27
     * @param {object} data
     */
    userLoginPasswordChangeSuccess(data) {
      this.resetUserLoginPasswordChangeDialog()
      this.userLogout()
    },

    /**
     * 使用者(修改)登入密碼
     * @date 2021-12-27
     */
    async userLoginPasswordChange() {
      if (this.validationType === 'sms') {
        await this.verifyMobileCode({
          phone: `${this.phoneCountryCode}|${this.form.contact_no}`,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if ((this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      // 信箱驗証
      if (this.validationType === 'email') {
        this.emailDialog.form.email = this.form.email
        const { status } = await this.validate_email_validate_code(this.emailDialog.form)
        if (status === '0') return this.validateEmailCodeFail()
      }

      this.$refs.mdfUserPasswordForm.validate()

      if (!this.userPasswordDialog.form.status) return false

      const res = await this.modify_user_password(this.userPasswordDialog.form)
      resStatus(
        res,
        this.userLoginPasswordChangeSuccess,
        this.userLoginPasswordChangeFail,
      )
    },

    /**
     * 錯誤彈窗
     * @date 2021-12-27
     */
    failDialog(errors) {
      if (!errors) {
        return this.show_alert({
          icon: 'fail',
        })
      }

      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 使用者登出 (成功)
     * @date 2021-12-27
     */
    userLogoutSuccess() {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
      })

      setTimeout(() => {
        this.$router.push({ path: '/login' })
      }, 500)
    },

    /**
     * 使用者登出
     * @date 2021-12-27
     */
    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    /**
     * (失敗) 取得 email 驗証碼
     * @date 2022-01-25
     * @param {array} errors 錯誤訊息
     */
    sendEmailValidateCodeFail(errors) {
      this.emailDialog.form.email = ''

      this.show_alert({
        icon: 'fail',
        text: errors[0],
      })
    },

    /**
     * (發送) email 驗証碼
     * @date 2022-01-07
     * @param {object} emailForm  { email }
     */
    async sendEmailValidateCode(emailForm, type) {
      if (!emailForm.email && this.edit.form.email) {
        emailForm.email = this.edit.form.email
      }

      // 有值且驗証成功才送請求
      if (type !== 'edit') {
        if (!this.$refs.emailInput.validate()) return false
      }
      if (!emailForm.email) return false

      this.reciprocalEmailSendValidateCodeBtn()

      const res = await this.send_email_validate_code(emailForm)

      // 成功取得 email 驗証碼
      if (res.status === '1') {
        return this.show_alert({
          icon: 'done',
        })
      }

      // 無法取得 email 驗証碼
      this.sendEmailValidateCodeFail(res.errors)
    },

    resetEmailForm() {
      this.emailDialog.status = false
      this.$refs.emailDialogForm.reset()
    },

    /**
     * 新增 email Dialog (CANCEL)
     * @date 2022-01-07
     */
    emailDialogClose() {
      this.resetEmailForm()
    },

    /**
     * 新增 email Dialog (確認)
     * @date 2022-01-07
     * @param {object} formData email 驗証資料 {email, code}
     */
    async emailDialogConfirm(formData) {
      this.$refs.emailDialogForm.validate()
      if (!this.emailDialog.form.status) return false
      this.validateEmailCode(formData)
    },

    /**
     * 驗証 email 驗証碼 (成功)
     * @date 2022-01-07
     */
    validateEmailCodeSuccess() {
      this.show_alert({
        icon: 'done',
      })
      this.form.email = this.emailDialog.form.email
      this.resetEmailForm()
    },

    /**
     * 驗証 email 驗証碼 (失敗)
     * @date 2022-01-07
     * @param {string} error
     */
    validateEmailCodeFail(errors) {
      this.emailDialog.form.validateCode = ''

      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 驗証 email 驗証碼
     * @date 2022-01-07
     * @param {object} formData { email, 驗証碼}
     */
    async validateEmailCode(formData) {
      const validateData = await this.validate_email_validate_code(formData)
      resStatus(
        validateData,
        this.validateEmailCodeSuccess,
        this.validateEmailCodeFail,
      )
    },

    /**
     * email 驗証倒數功能
     * @date 2022-01-07
     */
    reciprocalEmailSendValidateCodeBtn() {
      // disable 發送驗証碼 btn
      this.emailDialog.validate.codeBtn = true

      const timeId = setInterval(() => {
        if (this.emailDialog.validate.sendBtnSec === 0) {
          // 如果為 0 即重置
          clearInterval(this.emailDialog.validate.timeID)
          this.emailDialog.validate.sendBtnSec = 60
          this.emailDialog.validate.codeBtn = false

          // 每秒遞減
        } else this.emailDialog.validate.sendBtnSec--
      }, 1000)

      this.emailDialog.validate.timeID = timeId
    },

    settingGender() {
      if (this.disable.gender) return false
      this.selectDialog = true
    },
  },
}
