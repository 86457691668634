import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { detectDevIsLineLiffMode } from '@/utils/liff'
import ProfileIcon from '@/components/icon/Profile'
import Dialog from '@/components/dialog/index'
import { deviceCheck } from '@/utils/device'
import { getCookie } from '@/utils/cookie'

export default {
  components: {
    ProfileIcon,
    Dialog,
  },

  data: () => ({
    userBaseInfo: [],

    dialog: {
      status: false,
      avatarList: [],
      form: {
        avatar: '',
      },
    },

    // 當前語系
    activeLang: null,
    langSheetStatus: false, // 語系開關
    language: {
      status: false,
      searchKeyword: '',
      resultLangs: [],
    },
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      userData: state => state.user.userData,
      theme: state => state.theme,
      userWallet: state => state.wallet.main,

      primaryColor() {
        return this.$vuetify.theme.themes[this.theme]?.primary || ''
      },

      // 此站點是否顯示 vip 相關功能
      vipStatus: state => state.websiteData.func_switch.vip,

      nowVipData: state => state.vip.now,
      nextVipData: state => state.vip.next,
      vipProgress: state => state.vip.progress,
      // 控制選單 App是否顯示，後台：編輯營運商 / App提供下載類型
      appDownloadType: state => state.service.app.app_download_type,

      // 顯示 vip 相關
      showVip: state => state.websiteData.func_switch.vip,
      // 顯示龍虎榜
      dtrankDisplay: state => state.websiteData.func_switch.dtrank_display,

      // 可選語言
      switchLanguages: state => state.websiteData.lang_available,

      // line
      lineDisplayName: state => state.line.liff.displayName,
      lineAvatar: state => state.line.liff.avatar,

      free: state => state.wallet.free,
      player: state => state.wallet.player,
    }),

    ...mapGetters([
      'baseImgUrl',
      'lang',
      'dynamicAgentDisplay',
      'clientLang', // 前端當前切換語系
      'currencySymbol', // 營商幣別
      'currencySymbol_', // 營商幣別符號
      'style',
      'currentDevice',
      'isMycardDomainService',
      'theme',
      'layout',
    ]),

    profileClr() {
      return this.$vuetify.theme.themes[this.theme]?.icon
    },

    dynamicClr() {
      return this.style === 'darkGD' ? this.primaryColor : this.profileClr
    },

    avatarBorderClr() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes[this.theme]?.primary}`,
      }
    },

    /**
     * 底部路由導引項目
     * @date 2021-05-31
     * @returns {array} 路由導引項目 (index => 路由 / value => 文字)
     */
    pageItem() {
      let page = [
        {
          'faq/help': this.$t('footer.新手幫助'),
        },
        {
          'faq/problem': this.$t('myCenter.commonProblem'),
        },
        {
          app: this.$t('menu.app'),
        },
        {
          recommend: this.$t('myCenter.recommendBonus'),
        },
        {
          'faq/corporate-affairs': this.$t('myCenter.responsibility'),
        },
        {
          agent: this.$t('myCenter.joinUs'),
        },
      ]

      if (this.layout === 'template3' || this.layout === 'template4') {
        const addItem = [{
          'user/card': this.$t('global.cards'),
        },
        {
          'user/personal': this.$t('myCenter.personalInformation'),
        },
        {
          'user/vip': this.$t('myCenter.myVip'),
        },
        {
          'faq/problem': this.$t('myCenter.commonProblem'),
        },
        {
          'user/news': this.$t('myCenter.news'),
        }]

        page.unshift(...addItem)
      }

      if (this.dtrankDisplay) {
        page.unshift({
          'dt-list': this.$t('game.dragonTigerList'),
        })
      }

      // Liff 模式不顯示 app 項目
      // 當後台設定 App提供下載類型: none 時則不顯示 App選單
      if (this.isLiffMode || this.appDownloadType === 'none') {
        page = page.filter(item => Object.keys(item)[0] !== 'app')
      }

      return page
    },

    // 因應語系動態寬度
    itemsWidth() {
      const widthAry = ['en', 'vi-VN', 'ph']
      return widthAry.includes(this.clientLang) ? 6 : 4
    },

    // 不顯示的路由名稱
    noShowRouterLink() {
      const noDisplayList = []
      if (!this.isLogin) noDisplayList.push('recommend')
      if (!this.showVip) noDisplayList.push('faq/problem')
      if (!this.dynamicAgentDisplay) noDisplayList.push('agent')

      return noDisplayList
    },

    // 升級需要的 「存款」
    upgradeNeedDeposit() {
      const needNum = this.nextVipData.deposit_amount - this.vipProgress.deposit_amount
      if (!needNum) return '--'
      if (needNum < 0) return 0
      return needNum
    },

    // 升級需要的 「流水」
    upgradeNeedTurnover() {
      const needNum = this.nextVipData.bet_amount - this.vipProgress.bet_amount
      if (!needNum) return '--'
      if (needNum < 0) return 0
      return needNum
    },

    isLiffMode() {
      return detectDevIsLineLiffMode() || window.liff.isInClient()
    },
  },

  watch: {
    // 篩選語系
    'language.searchKeyword': {
      handler(searchKeyword) {
        this.language.resultLangs = this.switchLanguages.filter(lang => {
          const filterItem = ['code', 'default_name', 'localized_name']
          return filterItem.find(item => lang[item].includes(searchKeyword))
        })
      },
      immediate: true,
    },

    // 監聽切換 popup
    'language.status'(newStatus) {
      if (!newStatus) this.language.searchKeyword = ''
    },
  },

  mounted() {
    if (this.isLogin) {
      // 如果此站顯示 vip 功能，才取 vip 資料
      if (this.vipStatus) this.getUserVipData()
      this.getUserBaseInfo()
      this.getUserBalance()
      // 取得錢包列表
      this.getUserWalletList()
    }
    // 初始化「當前」語系
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) {
      this.activeLang = clientLang
      return false
    }
    this.activeLang = this.lang
  },

  methods: {
    ...mapActions([
      'get_user_vip',
      'get_user_base_info',
      'get_user_balance',
      'user_logout',
      'show_alert',
      'get_user_avatar_list',
      'set_user_avatar',
      'get_service_url',
      'get_website_theme',
      'get_homepage_layout_area',
      'set_main_balance',
      'set_locked_balance',
      'get_user_wallet_list',
    ]),

    ...mapMutations(['SET_FREE_BALANCE', 'SET_PLAYER_BALANCE']),

    /**
     * 取得使用者所有遊戲錢包 (成功)
     * @date 2021-09-24
     * @param {any} data
     * @returns {any}
     */
    getUserWalletListSuccess(data) {
      const { game, player, free } = data

      // 更新中心錢包金額 @player
      const mainBalance = player.find(item => item.wallet_code === 'player_balance')
      const lockedBalance = player.find(item => item.wallet_code === 'locked_balance')
      this.set_main_balance(Number(mainBalance.balance))
      this.set_locked_balance(Number(lockedBalance.balance))

      this.SET_FREE_BALANCE(free.find(item => item.wallet_code === 'free_wallet'))
      this.SET_PLAYER_BALANCE(player.find(item => item.wallet_code === 'player_balance'))
    },

    /**
     * 取得使用者所有遊戲錢包
     * @date 2021-09-24
     */
    async getUserWalletList() {
      console.log('getUserWalletList')
      const res = await this.get_user_wallet_list()
      resStatus(res, this.getUserWalletListSuccess, this.walletTransferFail)
    },

    // 設置前端語系
    settingClientLang(clientLang) {
      this.language.status = false
      if (clientLang === this.clientLang) return false

      this.$i18n.locale = clientLang
      sessionStorage.setItem('clientLang', clientLang)
      this.activeLang = clientLang

      // 更新 layout 資料
      this.get_website_theme()
      this.get_homepage_layout_area()
    },

    // TODO 取得vip資料 成功/失敗 處理
    getUserVipDataSuccess(data) {},
    getUserVipDataFail() {},

    async getUserVipData() {
      const userVipData = await this.get_user_vip()
      resStatus(
        userVipData,
        this.getUserVipDataSuccess,
        this.getUserVipDataFail,
      )
    },

    getUserBaseInfoSuccess(data) {
      this.userBaseInfo = data.member
      this.dialog.form.avatar = data.member.avatar
    },

    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },

    getUserBalanceSuccess(userMainWallet) {
      this.userWallet = userMainWallet.balance
    },

    /**
     * 取得使用者中心錢包
     * @date 2021-04-09
     */
    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
    },

    userLogoutSuccess(data) {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })

      setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 500)
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },

    /**
     * 使用者登出
     * @date 2021-04-09
     */
    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    /**
     * 取得頭像列表
     * @date 2021-04-09
     * @returns {object} 頭像列表
     */
    async getUserAvatarList() {
      const userAvatarList = await this.get_user_avatar_list()
      return userAvatarList
    },

    setUserAvatarList(data) {
      this.dialog.avatarList = data.avatars
    },

    /**
     * 設置頭像功能 (點選頭像)
     * @date 2021-07-21
     * @param {any} e
     * @param {any} fetchAvatarList=this.getUserAvatarList(
     */
    async changeUserAvatar(e, fetchAvatarList = this.getUserAvatarList()) {
      this.dialog.status = true
      resStatus(await fetchAvatarList, this.setUserAvatarList)
    },

    closeDialog() {
      this.dialog.status = false
      this.dialog.form.avatar = this.userBaseInfo.avatar
    },

    /**
     * 設置頭像 (成功)
     * @date 2021-07-21
     */
    setUserAvatarSuccess() {
      this.getUserBaseInfo()
      this.show_alert({
        icon: 'done',
        text: this.$t('flashMessage.editAvatarSuccess'),
      })
    },

    /**
     * 設置頭像 (失敗)
     * @date 2021-07-21
     */
    setUserAvatarFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('flashMessage.editAvatarFail'),
      })
    },

    /**
     * 設置頭像
     * @date 2021-07-21
     */
    async setUserAvatar() {
      const userAvatarUrl = { avatar: this.dialog.form.avatar }
      const res = await this.set_user_avatar(userAvatarUrl)
      this.dialog.status = false
      resStatus(res, this.setUserAvatarSuccess, this.setUserAvatarFail)
    },

    /**
     * 更換頭像前 判斷
     * 如果已登入 -> 更換頭像
     * 如果未登入 -> 登入頁面
     * @date 2021-07-29
     */
    preChangeUserAvatar() {
      if (this.isLogin) return this.changeUserAvatar()
      this.$router.push({ path: '/login' })
    },

    goQrCode() {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      const { href } = this.$router.resolve({ path: '/user' })
      const url = `${window.location.origin}/${href}`
      const device = deviceCheck()

      const data = {
        url,
        Authorization: getCookie('accessToken'),
        wtoken: sessionStorage.getItem('wtoken'),
        lang: this.lang,
      }

      this.$log(data)
      this.$log(device)

      try {
        if (device === 'ios') {
          window.webkit.messageHandlers.openQRCode.postMessage(data)
        } else if (device === 'android') {
          window.WebScript.openQRCode(JSON.stringify(data))
        } else this.$router.push({ path: '/qrcode' })
      } catch {
        this.$router.push({ path: '/qrcode' })
      }
    },

    // 卡片 && 存款 && 交易紀錄 按鈕功能
    goPageFunc(routerPath) {
      if (this.isLiffMode && this.isMycardDomainService) return false
      this.$router.push({ path: routerPath })
    },
  },
}
